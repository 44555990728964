.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.wrapper {
  margin-top: 10px;
  font-family: CoFoSansBold;
  min-height: 600px;
  width: 300px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  background-color: #007bfb;
  border-radius: 15px;
  color: white;
  align-self: center;
  text-align: center;
  padding-top: 40px;
}

.qr {
  z-index: 1;
}

.chevron {
  width: 30px;
  padding: 20px;
  align-self: center;
}

.white-square {
  height: 240px;
  width: 240px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
}

.place {
  font-size: 20px;
}

.number {
  font-size: 50px;
  line-height: 45px;
  margin-bottom: 45px;
}

.logo {
  width: 80px;
  align-self: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "CoFoSansBold";
  src: url("./CoFoSansBold.ttf");
  src: url("./CoFoSansBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

table {
  display: flex;
  justify-content: center;
}

button,
button:focus {
  outline: none;
  border: 1px solid transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  height: 48px;
  left: 20px;
  top: 52px;

  /* Secondary */

  background: #00B8B0;
  border-radius: 16px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.logo_company {
  margin-top: 20px;
  box-sizing: inherit;
  margin-bottom: 30px;
}

.main_wrap {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(0, 58, 93);
  min-height: 100vh;
  flex-direction: column;
}

.wrapper_drop_zone {
  width: 90%;
}

.drop_zone {
  width: 100%;
  height: 250px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}

.div_zone {
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.title {
  margin-top: 32px;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25;
  /* font-family: Inter, sans-serif; */
  text-align: center;
  color: rgb(0, 58, 93);
}

.sub_title {
  margin-top: 40px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  font-family: Inter, sans-serif;
  color: rgb(0, 58, 93);

  display: flex;
  align-items: center;
  justify-content: center;
}

.hint {
  margin-top: 30px;
  color: #809DAF;
  font-size: 0.8375rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  font-family: Inter, sans-serif;
}

.qr_palce_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: calc(100vh - 165px);
}

.left,
.rigth {
  display: flex;
  background-color: white;
  border-radius: 20px;
  width: calc(100vw / 2 - 60px);
  margin-bottom: 20px;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: 20px;

}

.rigth {
  padding: 20px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5;
  font-family: Inter, sans-serif;
  color: rgb(0, 58, 93);
}

.wrap {
  word-break: break-word;
  white-space: pre-wrap;
}

.item {
  margin-bottom: 320px;
}

.spacer {
  display: flex;
  flex: 1;
}

.footer {
  height: 50px;
  bottom: 0px;
  width: 100%;
  background-image: url(./foother.svg);
}